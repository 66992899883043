import { NoSsr } from "@mui/base";
import { MiniHeaderIndicatorContextProvider } from "@product/scmp-sdk";
import { useSetAtom } from "jotai";
import type { CSSProperties, FunctionComponent } from "react";

import { hamburgerMenuAtom } from "~/components/hamburger-menu/atoms";
import { DefaultHeaderSwapThreshold } from "~/components/header/consts";
import type { HeaderConfiguration } from "~/components/header/types";
import IconMenu from "~/components/home/header/header-icon/menu.svg";
import { useHomeHeader, useSwapOnScrollEffect } from "~/components/home/header/hooks";
import { ActionButton } from "~/components/home/header/styles";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import { useEditionValue } from "~/lib/edition";
import { useGetHomeAsPath } from "~/lib/router/hooks";
import { page as HomePage } from "~/pages/home";

import {
  Container,
  LeftFeatureContainer,
  MobileStyledLogoLink,
  RightFeatureContainer,
  StyledDynamicHeaderOnelineMenuContainer,
  StyledLogo,
  StyledLogoFlag,
  StyledLogoLink,
  Wrapper,
} from "./styles";

export type Props = {
  className?: string;
  headerConfiguration?: HeaderConfiguration;
  style?: CSSProperties;
};

export const MiniHeader: FunctionComponent<Props> = ({ className, headerConfiguration, style }) => {
  const edition = useEditionValue();
  const homeAsPath = useGetHomeAsPath();

  const setHamburgerMenuState = useSetAtom(hamburgerMenuAtom);

  const { shouldSwap } = useSwapOnScrollEffect(DefaultHeaderSwapThreshold);
  const { mastheadQueryParameter, renderFeatures } = useHomeHeader(shouldSwap, headerConfiguration);

  const { pathname: homePagePathname, query: homePageQuery } = HomePage.route({
    edition,
    slide: "main",
  });
  return (
    <Container className={className} style={style}>
      <Wrapper>
        <LeftFeatureContainer>
          <ActionButton
            $responsiveVariants={{
              desktopUp: "show",
              homeDesktopUp: "hidden",
              mobileUp: "show",
              tabletUp: "show",
            }}
            onClick={() => {
              setHamburgerMenuState(current => ({
                ...current,
                isOpen: !current.isOpen,
                variant: "home",
              }));
              sendGATracking({
                action: "Menu/Open",
                category: "Menu",
                label: "Menu/Open",
              });
            }}
          >
            <IconMenu />
          </ActionButton>

          <StyledLogoLink
            anchorProps={{ "aria-label": "SCMP homepage" }}
            as={homeAsPath}
            pathname={homePagePathname}
            query={{ ...homePageQuery, ...mastheadQueryParameter }}
          >
            <StyledLogoFlag />
          </StyledLogoLink>

          {renderFeatures("left")}
          <NoSsr>
            <StyledDynamicHeaderOnelineMenuContainer />
          </NoSsr>
        </LeftFeatureContainer>

        <MobileStyledLogoLink
          anchorProps={{ "aria-label": "SCMP homepage" }}
          as={homeAsPath}
          pathname={homePagePathname}
          query={{ ...homePageQuery, ...mastheadQueryParameter }}
        >
          <StyledLogo />
        </MobileStyledLogoLink>
        <MiniHeaderIndicatorContextProvider>
          <RightFeatureContainer>{renderFeatures("right", "mini-header")}</RightFeatureContainer>
        </MiniHeaderIndicatorContextProvider>
      </Wrapper>
    </Container>
  );
};

MiniHeader.displayName = "MiniHeader";
